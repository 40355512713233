<template>
  <v-container fluid class="pa-1" v-if="user_info.role <= 1">
    <v-row no-gutters>
      <v-col xl="12" lg="12" md="12" sm="12" cols="12">
        <v-data-table
          :headers="headers"
          :items="userArr"
          sort-by="calories"
          :hide-default-footer="true"
          class="elevation-1"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ topTitle }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="1000px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ plusTitle }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.email"
                            label="username (ชื่อสำหรับ Login)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.name"
                            label="ชื่อ สกุล"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.password"
                            label="รหัสผ่าน"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.password_confirmation"
                            label="ยืนยันรหัสผ่าน"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.role"
                            label="กลุ่มผู้ใช้"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.frm_userdetail"
                            label="แก้ไขข้อมูล น.ศ."
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.frm_check"
                            label="ให้ดาว (ตรวจจิต)"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.sm_login"
                            label="จำนวนเครื่องที่สามารถ Login พร้อมกัน"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.room"
                          label="สิทธิการเข้าถึงฐานข้อมูล (ห้อง)"
                        ></v-text-field>
                      </v-col>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      ยกเลิก
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      บันทึก
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    แน่ใจที่จะลบหรือไม่
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      ยกเลิก
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm"
                    >
                      ลบ
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
          </template>
          <template v-slot:no-data>
            <!-- <v-btn color="primary" @click="initialize"> reload </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

export default {
  data: () => ({
    topTitle: "ผู้ใช้งาน",
    plusTitle: "เพิ่มผู้ใช้งาน",
    dialog: false,
    dialogDelete: false,
    headers: [],
    userArr: [],
    editedIndex: -1,
    editedItem: {
      email: "",
      password: "",
      password_confirmation: "",
      name: "",
      role: "",
      room: "",
      frm_userdetail: "",
      frm_check: "",
      sm_login: "",
    },
    defaultItem: {
      email: "",
      password: "",
      password_confirmation: "",
      name: "",
      role: "",
      room: "",
      frm_userdetail: "",
      frm_check: "",
      sm_login: "",
    },
  }),

  computed: {
    ...mapState(["user_info"]),
    formTitle() {
      return this.editedIndex === -1 ? "เพิ่มรายการใหม่" : "แก้ไขรายละเอียด";
    },
    user_url: function () {
      return `${this.$store.state.apiurl}/api/user`;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.headers = [
      {
        text: "id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "username (ชื่อสำหรับ Login)",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: "ชื่อ สกุล",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "กลุ่มผู้ใช้",
        align: "start",
        sortable: false,
        value: "role",
      },
      {
        text: "สิทธิการเข้าถึงฐานข้อมูล (ห้อง)",
        align: "start",
        sortable: false,
        value: "room",
      },
      {
        text: "แก้ไขข้อมูล น.ศ.",
        align: "start",
        sortable: false,
        value: "frm_userdetail",
      },
      {
        text: "ให้ดาว (ตรวจจิต)",
        align: "start",
        sortable: false,
        value: "frm_check",
      },
      {
        text: "#Login ได้พร้อมกัน",
        align: "end",
        sortable: false,
        value: "sm_login",
      },

      { text: "#", value: "actions", sortable: false },
    ];
  },
  methods: {
    initialize() {
      this.getListSql();
    },
    async getListSql() {
      // var data = await axios.get(this.crudurl + id);
      var { data } = await axios.get(this.user_url, {});
      this.userArr = await data;
      // await console.log(this.userArr);
    },

    editItem(item) {
      this.editedIndex = this.userArr.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.userArr.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.userArr.splice(this.editedIndex, 1);
      await axios.post(this.user_url, {
        action: "delete",
        data: this.editedItem,
        token: localStorage.getItem("token"),
      });
      await this.initialize();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      // console.log(this.editedItem);
      var formData = await new FormData();
      await formData.append("email", this.editedItem.email);
      await formData.append("name", this.editedItem.name);
      await formData.append("role", this.editedItem.role);
      await formData.append("room", this.editedItem.room);
      await formData.append("frm_userdetail", this.editedItem.frm_userdetail);
      await formData.append("frm_check", this.editedItem.frm_check);
      await formData.append("sm_login", this.editedItem.sm_login);
      if (this.editedItem.password) {
        await formData.append("password", this.editedItem.password);
      }
      if (this.editedItem.password) {
        await formData.append(
          "password_confirmation",
          this.editedItem.password_confirmation
        );
      }
      if (this.editedIndex > -1) {
        await axios.post(
          this.user_url + "/" + this.editedItem.id + "?_method=PUT",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        await axios.post(`${this.$store.state.apiurl}/api/newuser`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      await this.initialize();
      await this.close();
    },
  },
};
</script>
